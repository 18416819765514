@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  overflow: scroll;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

/* Scrollbar customizations */
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  background-color: #222426;
}

body::-webkit-scrollbar-thumb {
  background-color: #5e5d5d;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.about-details::-webkit-scrollbar,
.product-details::-webkit-scrollbar {
  width: 0.5em;
}

.about-details::-webkit-scrollbar-track,
.product-details::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.about-details::-webkit-scrollbar-thumb,
.product-details::-webkit-scrollbar-thumb {
  background-color: #cfcece;
}

/* Header & Navigation */
.header-nav {
  position: fixed;
  display: flex;
  width: 100%;
  height: 8vh;
  justify-content: space-around;
  align-items: center;
  background-color: #222426;
  border-bottom: 2px solid #3d8af7;
  z-index: 9999;
}

.logo {
  max-width: 200px;
  height: auto;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav li:nth-child(-n+4) {
  border-right: 1px solid #757575;
}

nav li {
  display: inline-block;
}

nav a {
  cursor: pointer;
  padding: 20px;
  color: #ffffff;
  text-decoration: none;
  font-family: "Source Sans Pro";
  font-weight: lighter;
  font-size: 1.125rem;
}

nav li:hover a {
  color: #b8bbbf;
}

.nav-toggle {
  display: none;
  position: absolute !important;
  top: -9999px;
  left: -9999px;
}

.nav-toggle-label {
  display: none;
  position: absolute;
  z-index: 99;
  left: 5vw;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 2px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.4s;
}

.nav-toggle:checked ~ .nav-toggle-label .bar1 {
  transform: rotate(-45deg) translate(-3.6px, 2.4px);
  -o-transform: rotate(-45deg) translate(-3.6px, 2.4px);
  -moz-transform: rotate(-45deg) translate(-3.6px, 2.4px);
  -webkit-transform: rotate(-45deg) translate(-3.6px, 2.4px);
}

.nav-toggle:checked ~ .nav-toggle-label .bar2 {
  opacity: 0;
}

.nav-toggle:checked ~ .nav-toggle-label .bar3 {
  transform: rotate(45deg) translate(-8px, -8px);
  -o-transform: rotate(45deg) translate(-8px, -8px);
  -moz-transform: rotate(45deg) translate(-8px, -8px);
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
}

/* Slider */
.slider-container {
  position: relative;
  padding-top: 8vh;
  width: 100%;
  height: auto;
}

.slide img {
  display: block;
  justify-content: center;
  max-width: 100%;
  height: 75vh;
  object-fit: cover;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40vh;
  padding: 10px;
  color: #ffffff;
  font-size: x-large;
  font-weight: bold;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
}

.prev {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.001));
  border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 5px 5px 0;
  -ms-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0;
  transition: background 300ms ease-in-out;
  -webkit-transition: background 300ms ease-in-out;
  -moz-transition: background 300ms ease-in-out;
  -ms-transition: background 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out;
}

.next {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.001));
  border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -ms-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
  transition: background 300ms ease-in-out;
  -webkit-transition: background 300ms ease-in-out;
  -moz-transition: background 300ms ease-in-out;
  -ms-transition: background 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out;
}

.prev:hover,
.next:hover {
  color: #ffffff;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.prev:hover {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  transition: background 300ms ease-in-out;
  -webkit-transition: background 300ms ease-in-out;
  -moz-transition: background 300ms ease-in-out;
  -ms-transition: background 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out;
}

.next:hover {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  transition: background 300ms ease-in-out;
  -webkit-transition: background 300ms ease-in-out;
  -moz-transition: background 300ms ease-in-out;
  -ms-transition: background 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out;
}

.caption-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: #46494d; */
  background: radial-gradient(#4a4e52, #3c3f42);
  color: #ffffff;
  height: 17vh;
}

.caption-container h2 {
  margin: 0;
  padding: 0 5px 25px 5px;
  font-family: "Lato";
  font-weight: lighter;
}

.caption-container a {
  cursor: pointer;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  font-family: "Source Sans Pro";
  border: 2px solid #ffffff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.caption-container a:hover {
  background-color: #38393b;
  transform: scale(1.08);
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
}

/* About section */
.about-section {
  padding-top: 8vh;
  padding-bottom: 10vh;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.heading-grey {
  margin: 0;
  padding-top: 8vh;
  color: #606060;
  font-family: "Lato";
  font-weight: lighter;
  font-size: 2.5rem;
  text-align: center;
}

.heading-white {
  margin: 0;
  padding-top: 8vh;
  color: #ffffff;
  font-family: "Lato";
  font-weight: lighter;
  font-size: 2.5rem;
  text-align: center;
}

.subheading-grey {
  margin: 0;
  padding: 10px 0;
  color: #606060;
  font-family: "Lato";
  font-weight: bold;
  font-size: 1.625rem;
}

.paragraph-grey {
  margin: 5vh 0;
  padding: 0 15vw;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
  line-height: 1.5;
}

.paragraph-white {
  margin: 5vh 0;
  padding: 0 15vw;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
  line-height: 1.5;
}

.column-paragraph-grey {
  width: 20vw;
  margin: 0;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5vh 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
  margin: 0 35px 0 5px;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 0 20px;
  border: 1px solid #606060;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.check-mark {
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  height: 20px;
  width: 12px;
  border-bottom: 4px solid #606060;
  border-right: 4px solid #606060;
}

.read-more-button {
  cursor: pointer;
  margin-top: 10px;
  padding-left: 0;
  width: 100px;
  text-align: left;
  font-family: "Lato";
  font-size: 0.875rem;
  font-weight: bold;
  color: #3d8af7;
  background-color: transparent;
  border: none;
}

.read-more-button:hover {
  text-decoration: underline;
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -o-transform: scale(1.04);
}

.x-button-about {
  cursor: pointer;
  position: fixed;
  top: 16vh;
  right: 9.75vw;
  padding: 0.5px;
  border: none;
  background-color: rgb(250, 250, 250, 0);
}

.about-details {
  position: fixed;
  top: 14%;
  left: 10%;
  z-index: 99;
  overflow-y: auto;
  padding: 40px;
  width: 80vw;
  height: 80vh;
  max-width: 80vw;
  max-height: 80vh;
  background-color: #ffffff;
  outline: 12px solid #ffffff;
  outline-offset: -1px;
  box-shadow: 0 4px 20px 0 rgba(34, 36, 38, 0.8), 0 4px 20px 0 rgba(34, 36, 38, 0.79);
  /*transform: translate(-50%, -45%);
  -webkit-transform: translate(-50%, -45%);
  -moz-transform: translate(-50%, -45%);
  -ms-transform: translate(-50%, -45%);
  -o-transform: translate(-50%, -45%);*/
}

.about-details p, .about-details ul {
  color: #606060;
  font-family: "Lato";
  font-size: 1.0625rem;
  line-height: 1.5;
}

.about-details img {
  width: 750px;
  max-width: 100%;
  height: auto;
  margin: 35px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.img-container {
  display: flex;
  justify-content: center;
  height: auto;
  width: auto;
  max-width: 100%;
}

/* Products section */
.products-section {
  padding-top: 8vh;
  padding-bottom: 10vh;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #3d8af7;
}

.product-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.product {
  margin: 5vh 5vw;
  justify-content: center;
  align-items: center;
}

.product-button {
  cursor: pointer;
  background-color: rgba(51, 125, 228, 0);
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 10px 30px 10px;
  color: #ffffff;
  font-family: "Lato";
  font-weight: lighter;
  font-size: 1.125rem;
  text-align: center;
  transition: transform;
  -webkit-transition: transform;
  -moz-transition: transform;
  -ms-transition: transform;
  -o-transition: transform;
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
}

.product-button:hover {
  background-color: #357bdd;
  transition: transform;
  -webkit-transition: transform;
  -moz-transition: transform;
  -ms-transition: transform;
  -o-transition: transform;
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
  transform: scale(1.08) !important;
  -webkit-transform: scale(1.08) !important;
  -moz-transform: scale(1.08) !important;
  -ms-transform: scale(1.08) !important;
  -o-transform: scale(1.08) !important;
}

.x-button {
  cursor: pointer;
  position: fixed;
  top: 18vh;
  right: 11.5vw;
  padding: 0.5px;
  border: none;
  background-color: rgb(250, 250, 250, 0);
}

.x-button-bar1 {
  width: 35px;
  height: 2px;
  background-color: #606060;
  margin: 6px 0;
  transform: rotate(-45deg) translate(-3.6px, 2.4px);
  -o-transform: rotate(-45deg) translate(-3.6px, 2.4px);
  -moz-transform: rotate(-45deg) translate(-3.6px, 2.4px);
  -webkit-transform: rotate(-45deg) translate(-3.6px, 2.4px);
  -ms-transform: rotate(-45deg) translate(-3.6px, 2.4px);
}

.x-button-bar2 {
  width: 35px;
  height: 2px;
  background-color: #606060;
  margin: 6px 0;
  transform: rotate(45deg) translate(-3px, -3px);
  -o-transform: rotate(45deg) translate(-3px, -3px);
  -moz-transform: rotate(45deg) translate(-3px, -3px);
  -webkit-transform: rotate(45deg) translate(-3px, -3px);
  -ms-transform: rotate(45deg) translate(-3px, -3px);
}

.product-image {
  width: 350px;
  max-width: 90%;
  height: auto;
}

.product-details-image {
  grid-column: 1/2;
  max-width: 100%;
  height: auto;
  margin-right: 80px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.product-details {
  position: fixed;
  top: 15%;
  left: 10%;
  z-index: 99;
  overflow-y: auto;
  padding: 40px;
  width: 80vw;
  height: auto;
  min-height: 70vh;
  max-width: 90vw;
  max-height: 80vh;
  background-color: #ffffff;
  outline: 12px solid #ffffff;
  outline-offset: -1px;
  box-shadow: 0 4px 20px 0 rgba(34, 36, 38, 0.8), 0 4px 20px 0 rgba(34, 36, 38, 0.79);
  /* transform: translate(-50%, -45%);
  -webkit-transform: translate(-50%, -45%);
  -moz-transform: translate(-50%, -45%);
  -ms-transform: translate(-50%, -45%);
  -o-transform: translate(-50%, -45%); */
}

.product-details-grid {
  display: grid;
  grid-template-columns: auto 2fr;
}

.product-info {
  grid-column: 2/3;
  color: #606060;
  font-family: "Lato";
  font-size: 1.125rem;
}

.product-info-2 {
  grid-column: 1/3;
  color: #606060;
  font-family: "Lato";
  font-size: 1.125rem;
}

.product-title {
  font-weight: lighter;
  font-size: 2.5rem;
  margin-top: 0;
}

.product-procedures {
  display: flex;
  width: auto;
  max-width: 100%;
  height: auto;
  justify-content: space-around;
}

.product-procedures a {
  color: #ffffff;
}

/* Contact section */
.contact-section {
  display: flex;
  flex-direction: column;
  padding-top: 8vh;
  padding-bottom: 8vh;
  width: 100%;
  min-height: 100vh;
  background-color: #46494d;
}

.contact-paragraph {
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-self: center;
}

#message {
  width: 100%;
  margin-bottom: 25px;
  resize: none;
}

#company {
  width: 100%;
  height: 45px;
  margin-bottom: 25px;
}

#email {
  width: 40%;
  height: 45px;
}

#name {
  width: 40%;
  height: 45px;
  margin-left: 15px;
}

::placeholder,
::-webkit-input-placeholder {
  font-family: "Source Sans Pro";
  font-size: 1rem;
}

.send {
  cursor: pointer;
  width: calc(20% - 30px);
  height: 45px;
  margin-left: 15px;
  font-family: "Source Sans Pro";
  font-size: 1rem;
  color: #ffffff;
  background-color: #3d8af7;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
}

.send:hover {
  background-color: #2772db;
}

.form-feedback {
  position: fixed;
  top: 25%;
  left: 17.5%;
  z-index: 99;
  /* padding: 40px; */
  width: 65vw;
  max-width: 65vw;
  height: 60vh;
  max-height: 60vh;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(34, 36, 38, 0.8), 0 4px 20px 0 rgba(34, 36, 38, 0.79);
}

.form-feedback h2 {
  padding-bottom: 20px;
  text-align: center;
  font-family: "Lato";
  font-weight: bold;
  font-size: 1.625rem;
  color: #606060;
}

.form-feedback p {
  padding-bottom: 40px;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
  line-height: 1.5;
  color: #606060;
}

.ok-button {
  cursor: pointer;
  margin: 0 calc(50% - 70px);
  width: 140px;
  height: 45px;
  font-family: "Source Sans Pro";
  font-size: 1rem;
  color: #ffffff;
  background-color: #3d8af7;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
}

.ok-button:hover {
  background-color: #2772db;
}

.message-success, .message-failure {
  margin-top: 12%;
}

/* FAQ section */
.faq-section {
  padding-top: 8vh;
  padding-bottom: 10vh;
  width: 100%;
  min-height: 80vh;
  justify-content: center;
}

.faq-question {
  display: flex;
  align-items: center;
  margin: 30px 0;
  padding: 0 15vw;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.5;
}

.faq-question:first-of-type {
  padding-top: 50px;
}

.faq-answer {
  margin: 0 0;
  padding: 0 15vw;
  height: auto;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
  line-height: 1.5;
}

.plus-button {
  align-self: flex-start;
  cursor: pointer;
  margin-left: 15px;
  padding-top: 3px;
  /* padding-bottom: 3px; */
  width: 26px;
  height: 26px;
  color: #949597;
  background-color: #ffffff;
  border: 2px solid #949597;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  font-size: medium;
  font-weight: bold;
}

.plus-button:hover {
  color: #606060;
  border-color: #606060;
  transform: scale(1.08);
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
}

/* Footer */
.footer {
  display: flex;
  width: 100%;
  height: 20vh;
  min-height: 20vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #222426;
}

.footer-paragraph {
  font-size: 1rem;
  margin: 0;
}

/********************************************/
/********** Media screen max 360px **********/
/********************************************/
@media screen and (max-width: 375px) {
  .next,
  .prev {
    top: 28vh;
  }

  .slide img {
    height: 67vh;
  }

  .caption-container {
    height: 25vh;
  }
}

/************************************************************/
/*********** Media screen min 376px and max 415px ***********/
/************************************************************/
@media screen and (min-width: 376px) and (max-width: 415px) {
  .next,
  .prev {
    top: 30vh;
  }
}

/************************************************************/
/*********** Media screen min 416px and max 600px ***********/
/************************************************************/
@media screen and (min-width: 416px) and (max-width: 600px) {
  .slide img {
    height: 72vh;
  }

  .caption-container {
    height: 20vh;
  }
}

/********************************************/
/********** Media screen max 600px **********/
/********************************************/
@media screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

/********************************************/
/********** Media screen max 800px **********/
/********************************************/
@media screen and (max-width: 800px) {
  /* Header & navigation */
  .header-nav {
    justify-content: center;
  }

  .nav-toggle-label {
    display: block;
  }

  nav {
    transform: scale(1,0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
    position: absolute;
    top: 8vh;
    left: 0;
    z-index: 9999;
    margin: 0;
    overflow: hidden;
    -webkit-transform: scale(1,0);
    -moz-transform: scale(1,0);
    -ms-transform: scale(1,0);
    -o-transform: scale(1,0);
}

  nav ul {
    width: 100vw;
    height: 0;
    text-align: left;
    background-color: #e3e3e3;
  }

  nav li {
    padding: 15px 0;
  }

  nav li:nth-child(-n+4) {
    border-right: none;
  }

  nav li:hover {
    background-color: #b8bbbf;
  }

  nav li:hover a {
    color: #232323;
  }

  nav a {
    padding: 10px calc(100vw - 35px) 10px 35px;
    color: #232323;
    opacity: 0;
  }

  .nav-toggle:checked ~ nav ul {
    height: auto;
  }

  .nav-toggle:checked ~ nav {
    transform: scale(1,1);
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -o-transform: scale(1,1);
  }

  .nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 200ms ease-in-out 200ms;
    -webkit-transition: opacity 200ms ease-in-out 200ms;
    -moz-transition: opacity 200ms ease-in-out 200ms;
    -ms-transition: opacity 200ms ease-in-out 200ms;
    -o-transition: opacity 200ms ease-in-out 200ms;
  }

  /* Contact section */
  #email {
    width: 100%;
    margin-bottom: 25px;
  }

  #name {
    width: 100%;
    margin-left: 0;
    margin-bottom: 25px;
  }

  .send {
    width: 130px;
    max-width: 100%;
    margin-left: calc(50% - 65px);
  }

  /* FAQ section */
  .faq-question {
    padding: 0 10vw;
  }

  .faq-answer {
    padding: 0 10vw;
  }
}

/*********************************************/
/********** Media screen max 1345px **********/
/*********************************************/
@media screen and (max-width: 1345px) {
  /* About section */
  .flex-row {
    flex-direction: column;
    padding: 0;
  }

  .flex-column {
    margin: 0;
    padding: 0 20vw;
  }

  .column-paragraph-grey {
    width: 60vw;
  }

  .column-paragraph-grey:nth-child(1),
  .column-paragraph-grey:nth-child(2) {
    padding-bottom: 40px;
  }

  /* Product section */
  .circle {
    display: none;
  }

  .check-mark {
    display: none;
  }

  .product-details-grid {
    grid-template-columns: auto;
  }

  .product-info {
    grid-column: 1/2;
  }

  .product-info-2 {
    grid-column: 1/2;
  }

  .product-details-image {
    width: 300px;
    margin-bottom: 40px;
  }

  .contact-form {
    width: 75%;
  }
}